import routerOptions0 from "/var/www/sakhfood/frontend/sakhfood-front-20241010000809/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/var/www/sakhfood/frontend/sakhfood-front-20241010000809/app/router.options.ts";
const configRouterOptions = {
  scrollBehaviorType: "smooth",
  hashMode: false
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}